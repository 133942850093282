<template>
  <b-card header-tag="header" footer-tag="footer">
    <template v-slot:header>
      <h6 class="mb-0">Screensaver</h6> 
    </template>
    
    <b-alert show  variant="warning" v-if="current_screensaver==null">There is no available screensaver for this device yet</b-alert>
    <br>
    <b-alert show variant="success" v-if="current_screensaver!=null">There is a screensaver available, you can continue the configuration directly on the device (created on {{current_screensaver.created_at | formatDate}})<b-badge class="mr-1" variant="success" ></b-badge></b-alert>
    
    <br>
For the screensaver feature to work on your Sfir device you will need to upload one JPG to appear in the screen of your device, when no activity is detected, if you upload more than 1 JPG picture, all those images will be rendered as animations, one after the other. For the best experience, consider using only images 320 pixels height and 240 pixels width.
    <b-form-file
      multiple
      accept=".jpg, .JPG, .jpeg, .JPEG"
      v-model="files_to_upload"
    >
      <template slot="file-name" slot-scope="{ names }">
        <b-badge variant="dark">{{ names[0] }}</b-badge>
        <b-badge v-if="names.length > 1" variant="dark" class="ml-1">
          + {{ names.length - 1 }} More files
        </b-badge>
      </template>
    </b-form-file>
    <br />
    <div class="float-right">
      <br />
      <b-button
      ref="upload_btn"
        variant="danger"
        style="float-right"
        :disabled="files_to_upload == null"
        @click="uploadAttempt()"
        ><b-spinner small v-show="shouldBeSpinning()"></b-spinner> Upload</b-button
      >
    </div>
  </b-card>
</template>

<script>
import { UPDATE_SCREENSAVER,GET_SCREENSAVER } from "@/core/services/store/devices.module";
export default {
  name: "suitch-screensaver-widget",
  components: {},
  props: {
    device: null,
  },
  mounted() {
    this.downloadData();
  },
  data() {
    return {
      files_to_upload: null,
      uploading: false,
      current_device: { connection_type: -1 },
      current_screensaver: null,
    };
  },
  methods: {
      shouldBeSpinning(){
      if (this.$refs["upload_btn"]!=null) {
        return this.$refs["upload_btn"].disabled && this.uploading==true;
      }
      return false;
    },
    uploadAttempt() {
        this.$refs["upload_btn"].disabled=true;
        this.uploading=true;
        this.$forceUpdate();
      const formData = new FormData();
      for (let file of this.files_to_upload) {
        formData.append("screensaver[]", file, file.name); // note, no square-brackets
      }
      formData.append("token", this.device.token);
      this.$store
        .dispatch(UPDATE_SCREENSAVER, formData)
        // go to which page after successfully login
        .then((data) => {
          data;
          this.$refs["upload_btn"].disabled=false;
          this.uploading=false;
          this.$forceUpdate();
          this.downloadData();
        });
    },
    downloadData() {
      if (this.device != null) {
        this.$store
          .dispatch(GET_SCREENSAVER,{token:this.device.token})
          // go to which page after successfully login
          .then((data) => {
              if(data.result=="success"){
                  this.current_screensaver=data;
              }
              this.$forceUpdate();
            // download devices list
            // if (data == undefined || data == null) {
            //   // when null
            // } else {
            //   this.current_device = data;
            // }
          });
      }
    },
  },
  watch: {
    device: function(oldval, newval) {
      oldval, newval;
      this.downloadData();
    },
  },
};
</script>
